import { useAuth0 } from "@auth0/auth0-react";
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Chip, Grid, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AutocompleteArrayInput, useDataProvider, Button, DateInput, Edit, NullableBooleanInput, RadioButtonGroupInput, ReferenceInput, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, required, useRecordContext, useRedirect } from "react-admin";
import { useFormContext } from "react-hook-form";
import { createAuthenticatedClient, getApiBaseUrl } from "../../data/documentsDataProvider";


const style = {
  item: {
    borderStyle: "solid", borderWidth: "0.5px", borderColor: "silver", maxWidth: "30%", borderRadius: "25px", marginTop: "10px"
  },
  header: {
    borderBottomStyle: "solid", borderWidth: "0.5px", borderColor: "silver", padding: "1px 1px 1px 0px"
  },
  title: {
    color: "#3f4a6e"
  },
  container: {
    padding: "10px", height: ""
  },
  info: {
    color: "#3f4a6e"
  }
}

const DocumentTitle = ({ record }: any) => {
  return <span>Document {record ? `"${record.title}"` : ''}</span>;
};

const DocumentEditToolbar = (props: any) => {
  const redirect = useRedirect();

  return (
    <Toolbar {...props} >
      <Grid container direction={"row"} columnGap={1}>
        <SaveButton label="save"
          transform={transformRisk}
          type={"button"}/>
        <SaveButton
          label="publish"
          transform={transformer}
          type="button"
          mutationOptions={{
            onSuccess: () => {
              redirect("/documents");
            }
          }
          } />
      </Grid>
    </Toolbar>
  )
};

const transformRisk = (record: any) => {
  console.log(record)

  for (const k in record){
    if (record[k] == "true"){
      record[k] = true;
    }
    else if (record[k] == "false"){
      record[k] = false;
    }
    else if (record[k] == "N/A") {
      record[k] = null;
    }
  }
  console.log(record)
  return record
}

const transformer = (record: any) => {
  record.published = true;
  return transformRisk(record);
}

const RiskCalculationSideBarElement = ({ title, children }:any) => {

  return (
    <Grid container alignItems={"flex-start"}>
      <Grid container alignItems={"flex-start"} xs={12} sx={style.header}>
        <h4 style={{ margin: 0, paddingBottom: 5, fontWeight: "normal", fontStyle: "italic", color: "#3f4a6e"}}>{title}</h4>
      </Grid>
      <Grid container item alignItems={"flex-start"} columnGap={5}>
        {children}
      </Grid>
    </Grid>
  )

}

export const CalculateButton = (props:any) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const {cb, lookup, ...rest} = props;
  if(record == null) {
    return <div />
  }

  const handleClick = () => {
    console.log(record);
    const order: Record<string, string> = {
      'veranderingInRechtspraak': 'has_change_jurisdiction',
      'uitspraakHogereRechtscollege': 'has_higher_law_college',
      'actualiteitswaarde': 'has_actuality',
      'precedentenBijRechter': 'has_precedent_judge',

      'vernieuwedePassage': 'has_renewed_passage',
      'precedenten': 'has_precedents',

      'verwijzingRechtsleer': 'has_ref_legal_doctrine',
      'verwijzingRechtspraak': 'has_ref_jurisdiction',
      'aantalBladzijden': 'has_page_10',
      'tegenstrijdigeUitspraken': 'has_contradictions',
      'uitsprakenDezelfdeRechter': 'has_ruling_same_judge',
      'uitsprakenDezelfdeMaterie': 'has_ruling_same_matter',
      'bruikbaarheidAndereZaken': 'is_useful',

      'veranderingInWetgeving': 'has_change_of_law',
      'wetgevingBijUitspraak': 'has_law_by_ruling',
      'wetgevingVanToepassingOpOgenblikVanDeFeiten': 'has_law_at_facts',

      'datumPleidooien': 'has_date_pleadings',
      'tijdTussenFeiten/Uitspraak': 'has_time_between_facts',
      'datumUitspraak': 'has_date_ruling',
      'datumFeiten': 'has_date_facts',
      'datumBetwisting': 'has_date_dispute',
    };
    let values: Record<string, any> = {};

    Object.keys(order).forEach(function (key) {
      const value = order[key];
      const e = lookup(value)
      if (e != 'N/A') {
        if (typeof e === 'string' || e instanceof String) {
          values[key] = (e === 'true');
        } else {
          values[key] = e;
        }
      } else {
        values[key] = null;
      }
    });

    dataProvider.calculateScore({ data: values }).then((value: any) => cb(value["score"]))
  };

  return <Button label="" sx={{height: "50px"}} onClick={handleClick}><RefreshIcon/></Button>
}

const DocumentEditor = (props: any) => {

  const [suggestions, setSuggestions] = useState([]);
  const [score, setScore] = useState(0)
  let record = useRecordContext();
  //if (record) {
  //  setScore(record.risk);
  //}

  const { getValues, setValue } = useFormContext();

  const CKEditorInput = React.lazy(() => import('../CKEditorInput'));

  const clearTag = (event: any, label: any) => {
    event.stopPropagation();
    let labels = getValues("labels");
    labels = labels.filter((element: any) => element != label.name);
    setValue("labels", labels);
  }

  const {
    getAccessTokenSilently
  } = useAuth0();

  useEffect(() => {
    (async () => {
      let token = await getAccessTokenSilently();
      //change("verdict_date", moment(Number(getFieldState("verdict_date")?.value) * 1000).format('YYYY-MM-DD'));
      let tags = await createAuthenticatedClient(getApiBaseUrl() + '/tags', {}, token);
      setSuggestions(JSON.parse(tags.body).tags);
    })();
  }, []);

  return (
    <Grid container xs={12}>
      <TextInput source="title" validate={required()} fullWidth />
      <TextInput source="article" validate={required()} fullWidth />
      <ReferenceInput reference="courts" source="court_id">
        <SelectInput source="court_id" validate={required()} optionText="content" fullWidth />
      </ReferenceInput>
      <AutocompleteArrayInput source="tags" choices={suggestions} optionValue="id" optionText='name' fullWidth />
      <DateInput source="verdict_date" fullWidth />
      <NullableBooleanInput source="is_convicted" />

      <Grid container xs={12} direction={"row"}>
        <Grid container item xs={9}>
          <CKEditorInput source="content" input={record.content} />
        </Grid>

        <Grid container alignItems={"flex-start"} item xs={3} sx={{paddingLeft: 2}}>
          <Grid container justifyContent={"center"} alignItems={"center"} sx={{minHeight: "50px", maxHeight: "50px"}}>
            <h3 style={{margin: 0, ...style.title}}>Risk calculation</h3>
          </Grid>
          <Grid container overflow={"scroll"} sx={{minHeight: "670px", maxHeight: "670px"}}>
            <RiskCalculationSideBarElement title={"Jurisdiction"}>
                <RadioButtonGroupInput source="has_change_jurisdiction" label={<Grid container alignItems={"center"} columnGap={1}>verandering rechtspraak<Tooltip title="Is er sprake van verandering in rechtspraak?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}> </RadioButtonGroupInput>
              <RadioButtonGroupInput source="has_higher_law_college" label={<Grid container alignItems={"center"} columnGap={1}>hoger rechtscollege<Tooltip title="Is er reeds een uitspraak hoger rechtscollege?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
              ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
              <RadioButtonGroupInput source="has_actuality" label={<Grid container alignItems={"center"} columnGap={1}>actualiteitswaarde<Tooltip title="Heeft de uitspraak een actualiteitswaarde?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
              ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
              <RadioButtonGroupInput source="has_precedent_judge" label={<Grid container alignItems={"center"} columnGap={1}>precedent rechtscollege<Tooltip title="Is er al een uitspraak van hetzelfde rechtscollege?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
              ]} defaultValue={'N/A'}/>
              </RiskCalculationSideBarElement>
              <RiskCalculationSideBarElement title={"Specificity"}>
                <RadioButtonGroupInput source="has_renewed_passage" label={<Grid container alignItems={"center"} columnGap={1}>vernieuwde passage<Tooltip title="Omvat uitspraak een vernieuwde passage?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
                <RadioButtonGroupInput source="has_precedents" label={<Grid container alignItems={"center"} columnGap={1}>precedenten<Tooltip title="Zijn er precedenten"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'}/>
              </RiskCalculationSideBarElement>
              <RiskCalculationSideBarElement title={"Content"}>
                <RadioButtonGroupInput source="has_ref_legal_doctrine" label={<Grid container alignItems={"center"} columnGap={1}>verwijzing rechtsleer<Tooltip title="Zijn er verwijzingen in de rechtsleer?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
                <RadioButtonGroupInput source="has_ref_jurisdiction" label={<Grid container alignItems={"center"} columnGap={1}>verwijzing rechtspraak<Tooltip title="Zijn er verwijzingen in de rechtspraak?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
                <RadioButtonGroupInput source="has_page_10" label={<Grid container alignItems={"center"} columnGap={1}>meer dan 10 paginas<Tooltip title="Bevat de uitspraak 10 of meer bladzijden?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
                <RadioButtonGroupInput source="has_contradictions" label={<Grid container alignItems={"center"} columnGap={1}>tegenstrijdige uitspraken<Tooltip title="Is er sprake van tegenstrijdige uitspraken?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
                <RadioButtonGroupInput source="has_ruling_same_judge" label={<Grid container alignItems={"center"} columnGap={1}>uitspraken zelfde rechter<Tooltip title="Zijn er uitspraken van dezelfde rechtbank?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
                <RadioButtonGroupInput source="has_ruling_same_matter" label={<Grid container alignItems={"center"} columnGap={1}>uitspraken zelfde materie<Tooltip title="Zijn er uitspraken met dezelfde materie?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
                <RadioButtonGroupInput source="is_useful" label={<Grid container alignItems={"center"} columnGap={1}>bruikbaarheid in andere zaken<Tooltip title="Is dit bruikbaar voor andere zaken?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'}/>
              </RiskCalculationSideBarElement>
              <RiskCalculationSideBarElement title={"Law"}>
                <RadioButtonGroupInput source="has_change_of_law" label={<Grid container alignItems={"center"} columnGap={1}>verandering wetgeving<Tooltip title="Is er sprake van verandering in wetgeving?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
                <RadioButtonGroupInput source="has_law_by_ruling" label={<Grid container alignItems={"center"} columnGap={1}>wetgeving bij uitspraak<Tooltip title="Wordt er wetgeving bij uitspraak geciteerd?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
                <RadioButtonGroupInput source="has_law_at_facts" label={<Grid container alignItems={"center"} columnGap={1}>wetgeving ogenblik feiten<Tooltip title="Omvat het document de wetgeving die van toepassing was op het ogenblik van de feiten?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'}/>
              </RiskCalculationSideBarElement>
              <RiskCalculationSideBarElement title={"Time"}>
                <RadioButtonGroupInput source="has_date_pleadings" label={<Grid container alignItems={"center"} columnGap={1}>datum pleidooien<Tooltip title="Omvat het document de datum van het pleidooien?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
                <RadioButtonGroupInput source="has_time_between_facts" label={<Grid container alignItems={"center"} columnGap={1}>tijd tussen feiten<Tooltip title="Omvat het document de tijd tussen de feiten en de uitspraak?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
                <RadioButtonGroupInput source="has_date_ruling" label={<Grid container alignItems={"center"} columnGap={1}>datum uitspraak<Tooltip title="Omvat het document de datum van de uitspraak?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
                <RadioButtonGroupInput source="has_date_facts" label={<Grid container alignItems={"center"} columnGap={1}>datum feiten<Tooltip title="Omvat het document de datum van de feiten?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'} sx={{maxHeight: "60px"}}/>
                <RadioButtonGroupInput source="has_date_dispute" label={<Grid container alignItems={"center"} columnGap={1}>datum betwisting<Tooltip title="Omvat het document de datum van de betwisting?"><InfoIcon sx={{ marginBottom: 0.70, fontSize: 'large', ...style.info}} /></Tooltip></Grid>} choices={[
                  {id: true, name: 'Yes'}, {id: false, name:'No'}, {id: 'N/A', name:'N/A'}
                ]} defaultValue={'N/A'}/>
              </RiskCalculationSideBarElement>
          </Grid>

          <Grid container justifyContent={"center"} alignItems={"center"} sx={{minHeight: "40px", maxHeight: "40px"}} columnGap={1}>
            <p style={{fontSize: "1.4em", margin: 0, color: "#3f4a6e"}}>Risk: {score > 0 ? score : record ? record.risk : 0}%</p>
            <CalculateButton cb={setScore} lookup={getValues} />
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}


export const DocumentEdit = (props: any) => {

  return (<Edit {...props} title={<DocumentTitle />} mutationMode="pessimistic">
    <SimpleForm toolbar={<DocumentEditToolbar />}>
      <DocumentEditor />
    </SimpleForm>
  </Edit >);
};


export default DocumentEdit;