import { Button, CheckboxGroupInput, Create, SimpleForm, TextInput } from "ra-ui-materialui"
import { useUser } from "../../model/User";
import { FC, useEffect, useState } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { FormDataConsumer, useDataProvider } from "react-admin";
import { Grid } from "@mui/material";


const style = {
    item: {
        borderStyle: "solid", borderWidth: "0.5px", borderColor: "silver", maxWidth: "30%", borderRadius: "25px", marginTop: "10px"
    },
    header: {
        borderBottomStyle: "solid", borderWidth: "0.5px", borderColor: "silver", padding: "1px 1px 1px 10px", backgroundColor: "#3f4a6e", borderTopLeftRadius: "25px", borderTopRightRadius: "25px"
    },
    title: {
        color: "white" 
    }, 
    container: {
        padding: "10px", height: ""
    }
}

const _AssessmentForm = (props: any) => {
    const dataProvider = useDataProvider();
    const {getFieldState, getValues, setValue} = useFormContext();
    const [score, setScore] = useState(0)


    const onClick = () => {
        const fields = ["jurisdiction", "specificity", "content", "law", "time"]; 
        let values: any = {}

        for (let field of fields) {
            let names = getValues(field)
            console.log(names)
            if (names != undefined && Array.isArray(names)) {
                for (let name of names) {
                    values[name] = true
                }
            }
        }

        dataProvider.calculateScore({ data: values }).then((value: any) => setScore(value["score"]))
    }

    return (
        <Grid xs={12} container justifyContent={"space-between"}>
            <Grid xs={4} style={style.item}>
                <Grid xs={12} sx={style.header}>
                    <h4 style={style.title}>Jurisdiction</h4>
                </Grid>
                <Grid xs={12} style={style.container}>
                    <CheckboxGroupInput source="jurisdiction" row={false} label="" choices={[
                        { id: 'veranderingInRechtspraak', name: 'Is er sprake van verandering in rechtspraak?' },
                        { id: 'uitspraakHogereRechtscollege', name: 'Is er reeds een uitspraak hoger rechtscollege?' },
                        { id: 'actualiteitswaarde', name: 'Heeft de uitspraak een actualiteitswaarde?' },
                        { id: 'precedentenBijRechter', name: 'Is er al een uitspraak van dezelfde rechtscollege?' },
                    ]} />
                </Grid>

            </Grid>

            <Grid xs={4} style={style.item}>
                <Grid xs={12} sx={style.header}>
                    <h4 style={style.title}>Specificity</h4>
                </Grid>
                <Grid xs={12} style={style.container}>
                    <CheckboxGroupInput source="specificity" row={false} label="" choices={[
                        { id: 'vernieuwedePassage', name: 'Omvat uitspraak een vernieuwde passage?' },
                        { id: 'precedenten', name: 'Zijn er precedenten' },
                    ]} />
                </Grid>

            </Grid>

            <Grid xs={4} style={style.item}>
                <Grid xs={12} sx={style.header}>
                    <h4 style={style.title}>Content</h4>
                </Grid>
                <Grid xs={12} style={style.container}>
                    <CheckboxGroupInput source="content" row={false} label="" choices={[
                        { id: 'verwijzingRechtsleer', name: 'Zijn er verwijzingen in de rechtsleer?' },
                        { id: 'verwijzingRechtspraak', name: 'Zijn er verwijzingen in de rechtspraak?' },
                        { id: 'aantalBladzijden', name: 'Bevat de uitspraak 10 of meer bladzijden?' },
                        { id: 'tegenstrijdigeUitspraken', name: 'Is er sprake van tegenstrijdige uitspraken?' },
                        { id: 'uitsprakenDezelfdeRechter', name: 'Zijn er uitspraken van dezelfde rechtbank?' },
                        { id: 'uitsprakenDezelfdeMaterie', name: 'Zijn er uitspraken met dezelfde materie?' },
                        { id: 'bruikbaarheidAndereZaken', name: 'Is dit bruikbaar voor andere zaken?' },
                    ]} />
                </Grid>

            </Grid>

            <Grid xs={4} style={style.item}>
                <Grid xs={12} sx={style.header}>
                    <h4 style={style.title}>Law</h4>
                </Grid>
                <Grid xs={12} style={style.container}>
                    <CheckboxGroupInput source="law" row={false} label="" choices={[
                        { id: 'veranderingInWetgeving', name: 'Is er sprake van verandering in wetgeving?' },
                        { id: 'wetgevingBijUitspraak', name: 'Wordt er wetgeving bij uitspraak geciteerd?' },
                        { id: 'wetgevingVanToepassingOpOgenblikVanDeFeiten', name: 'Omvat het document de wetgeving die van toepassing was op het ogenblik van de feiten?' },
                    ]} />
                </Grid>

            </Grid>

            <Grid xs={4} style={style.item}>
                <Grid xs={12} sx={style.header}>
                    <h4 style={style.title}>Time</h4>
                </Grid>
                <Grid xs={12} style={style.container}>
                    <CheckboxGroupInput source="time" row={false} label="" choices={[
                        { id: 'datumPleidooien', name: 'Omvat het document de datum van het pleidooien?' },
                        { id: 'tijdTussenFeiten/Uitspraak', name: 'Omvat het document de tijd tussen de feiten en de uitspraak?' },
                        { id: 'datumUitspraak', name: 'Omvat het document de datum van de uitspraak?' },
                        { id: 'datumFeiten', name: 'Omvat het document de datum van de feiten?' },
                        { id: 'datumBetwisting', name: 'Omvat het document de datum van de betwisting?' },
                    ]} />
                </Grid>

            </Grid>

            <Grid xs={4} container alignContent="center" justifyContent="center" style={{maxWidth: "30%"}}>
                <p style={{fontSize: "2em", margin: 0}}>{score} %</p>
                <Button label="Calculate" onClick={() => onClick()} fullWidth />
            </Grid>
        </Grid>
    )
}

export const RiskAssesment = (props: any) => {
    const { user, loading } = useUser();
    if (!user || user == undefined || !user.canView("risk assessment")) {
        return null;
    }

    return (
        <Create {...props} actions={false} hasCreate={user.canCreate("risk assessment")}>
            <SimpleForm toolbar={false}>
                <_AssessmentForm />
            </SimpleForm>
        </Create>

    )
}