import  stringify  from 'query-string';
import { fetchUtils, DataProvider, HttpError } from 'ra-core';
import { UploadStatus } from '../App';

const httpClient = fetchUtils.fetchJson;
const countHeader: string = 'Content-Range'

export const createAuthenticatedClient = (url: any, options: any = {}, token: string) => {
    options.user = {
        authenticated: true,
        token: `Bearer ${token}`
    };
    return fetchUtils.fetchJson(url, options);
};

export const getApiBaseUrl = () => process.env.REACT_APP_API_BASE_URL || 'http://localhost:8888';
export const getApiGPTUrl = () => "https://api.lauwers.ml2grow.cloud";
export const getChatAPIURL = () => "https://chat-fastapi.lauwers.ml2grow.cloud"

export default (
    getAccessTokenSilently: () => Promise<any>,
    updateStatus: (uploadStatuses: UploadStatus) => void
): DataProvider => ({
    getList: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
 
        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([rangeStart, rangeEnd]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${getApiBaseUrl()}/${resource}?${stringify.stringify(query)}`;
        const options =
            countHeader === 'Content-Range'
                ? {
                    // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                    headers: new Headers({
                        Range: `${resource}=${rangeStart}-${rangeEnd}`,
                    }),
                }
                : {};

        let token = await getAccessTokenSilently();
        return createAuthenticatedClient(url, options, token).then(({ headers, json }) => {
            let total = 0;
            if (headers.has(countHeader)) {
                total = countHeader === 'Content-Range'
                    ? parseInt(
                        headers.get('content-range')!.split('/').pop()!,
                        10
                    )
                    : parseInt(headers.get(countHeader.toLowerCase())!);
            } else {
                total = json[resource].length
            }
            return {
                data: json[resource],
                total: total
            };
        });
    },

    getOne: async (resource, params) => {
        let token = await getAccessTokenSilently();
        console.log('getOne');
        return createAuthenticatedClient(`${getApiBaseUrl()}/${resource}/${params.id}`, {}, token).then(({ json }) => ({
            data: json,
        }))
    },
    getMany: async (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${getApiBaseUrl()}/${resource}?${stringify.stringify(query)}`;
        let token = await getAccessTokenSilently();

        return createAuthenticatedClient(url, {}, token).then(({ json }) => ({ data: json[resource] }));
    },

    getManyReference: async (resource, params) => {
        return new Promise((resolve, reject) => {
            reject('Not implemented');
        })
    },

    update: async (resource, params) => {
        let token = await getAccessTokenSilently();
        return createAuthenticatedClient(`${getApiBaseUrl()}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }, token).then(({ json }) => ({ data: json }))

    },

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: async (resource, params) => {
        let token = await getAccessTokenSilently();

        return Promise.all(
            params.ids.map(id =>
                createAuthenticatedClient(`${getApiBaseUrl()}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                }, token)
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) }))
    },
    create: async (resource, params) => {
        let token = await getAccessTokenSilently();
        
        if (resource === 'documents' && (params as any).data.from_document) {
            if (!(params as any).data.files) {
                throw new Error("You need upload at least 1 file.");
            }
            if ((params as any).data.files.length > 20) {
                throw new Error("You can only upload max 20 documents at a time.");
            }

            let results: any[] = [];
            let errors: number = 0;

            for (let file of (params as any).data.files) {
                updateStatus({src: file.src, status: "PENDING", url: ''});
            }

            for (let file of (params as any).data.files) {
                updateStatus({src: file.src, status: "UPLOADING", url: ''});

                try {
                    const formData = new FormData()
                    formData.append('file', file.rawFile)
                    const response = await createAuthenticatedClient(`${getApiBaseUrl()}/${resource}`, {
                        method: 'POST',
                        body: formData,
                    }, token);
                    const parsed = JSON.parse(response.body);
                    results.push(parsed);
                    updateStatus({src: file.src, status: "UPLOADED", url: "/documents/".concat(parsed.id)});
                } catch (e) {
                    errors++;
                    if (e instanceof HttpError && e.status == 406) {
                        if (e.body.id.length > 0) {
                            updateStatus({src: file.src, status: "DUPLICATE", url: "/documents/".concat(e.body.id)});
                        } else {
                            updateStatus({src: file.src, status: "SCAN", url: ""});
                        }
                    } else {
                        updateStatus({src: file.src, status: "ERROR", url: ''});
                    }
                }
            }
            // Makes sure we don't navigate too fast.
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(undefined);
                }, 1000)
            });

            if (errors > 0) {
                throw new Error("There were errors uploading the documents");
            }


            return {
                data: results[0]
            }



        }

        return createAuthenticatedClient(`${getApiBaseUrl()}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }, token).then(({ json }) => {
            console.log(json);
            return {
                data: json,
            }
        });


    },
    delete: async (resource, params) => {
        let token = await getAccessTokenSilently();

        return createAuthenticatedClient(`${getApiBaseUrl()}/${resource}/${params.id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'text/plain',
            }),
        }, token).then(({ json }) => ({ data: json }))
    },
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: async (resource, params) => {
        let token = await getAccessTokenSilently();

        return Promise.all(
            params.ids.map(id =>
                createAuthenticatedClient(`${getApiBaseUrl()}/${resource}/${id}`, {
                    method: 'DELETE',
                    headers: new Headers({
                        'Content-Type': 'text/plain',
                    }),
                }, token)
            )
        ).then(responses => ({
            data: responses.map(({ json }) => json.id),
        }))
    },
    getPredictionGPT: async (input_text:string) => {
        const body = {"input_text":input_text}

        return fetch(`${getApiGPTUrl()}/run/predict`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Accept': 'application/json',
                'Content-Type': ' application/json',
            },
        })
        .then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        )
        .then(({ status, statusText, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            if (status < 200 || status >= 300) {
                return Promise.reject(
                    new HttpError(
                        (json && json.message) || statusText,
                        status,
                        json
                    )
                );
            }
            return Promise.resolve({ status, headers, body, json });
        }).then(({ json }) =>  {return(json.predicted_text)});
    },

    calculateScore: async (params:any) : Promise<any> => {
        let token = await getAccessTokenSilently();

        return createAuthenticatedClient(`${getApiBaseUrl()}/score`, {
            method: 'POST',
            body: JSON.stringify(params),
        }, token).then(({ json }) => {
            return json
        });


    },

    sendChatMessage: async (params:any) : Promise<any> => {
        let token = await getAccessTokenSilently();

        return createAuthenticatedClient(`${getApiBaseUrl()}/chat`, {
            method: 'POST',
            body: JSON.stringify(params),
        }, token).then(({ json }) => {
            return json
        });

    },

});